import { Component, OnDestroy, OnInit } from '@angular/core';
import Quill from 'quill';
import QuillResizeModule from './core/classes/QuillResizer/main';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { getProp, tokenExists } from './core/helpers/tokenReader';
import { AuthService } from './core/services/auth.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WebSocketService } from './core/services/web-socket.service';
import { SocketMensaje } from './core/types/SocketMensaje';
import { GlobalService } from './core/services/global.service';
import { MatSnackBar } from '@angular/material/snack-bar';

Quill.register("modules/imageResize", QuillResizeModule);

@Component({
standalone: false,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Estudio3R';
  tieneToken = false;
  tipoCuenta = -1;
  mostrarBarraNavegacion = false;

  private subs = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private webSocketService: WebSocketService,
    private _globalService: GlobalService,
    private _snackBar: MatSnackBar
    ){
      this.addIcons();
      this.tieneToken = tokenExists();  

      this.subs.add(this.authService.estaLogueado.subscribe(isLoggedIn => {        
        this.tieneToken = isLoggedIn;
        this.tipoCuenta = getProp("user.tipo");    
      }));

      this.webSocketService.listen("suspendido")
      .subscribe((data: SocketMensaje) => {
        this.getEstadoSuspendido()  
      })

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        // Aquí puedes realizar acciones una vez que la navegación se haya completado
        this.mostrarBarraNavegacion = this.authService.estaLogueado && event.url !== '/auth';
        // Realizar cualquier acción necesaria
      });
      /*this.subs.add(this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.mostrarBarraNavegacion = this.authService.estaLogueado && event.url !== '/auth';
        }
      }))*/
    }


  ngOnInit(): void {
    this.tipoCuenta = getProp("user.tipo");  
  }

  addIcons(){
    const icons = ['building-company','home','calendar','components','money-symbol','settings','shopping-cart','pig-money',
    'layout-grid','layout-grid-filled','kid_star','logoE3R', 'logoE3R2025', 'logoE3R2025Blanco','history-toggle','file-pencil','browser-check','folder-off',
    'list-details','transfer','file-dollar','credit-card-refund','apps','folder-dollar','users-group','edit','building-skyscraper',
    'users', 'camera_alt', 'videocam', 'straighten', 'branding_watermark'];

    icons.forEach(icono => {
      this.matIconRegistry.addSvgIcon(
        icono,
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/custom-icons/'+icono+'.svg')
      );
    });
  }

  getEstadoSuspendido(){
    this._globalService.getSuspendido().then((res:any) => {
      if(res.suspendido == 1){
        this.tieneToken = false
        localStorage.removeItem('token')
        this._snackBar.open(
          'Su cuenta fue suspendida. Si considera que fue un error comuníquese con un administrador.',
          'Cerrar',
          {
            duration: 10000,
            panelClass: ['error'],
          }
        );

        setTimeout(() => {
          this.router.navigateByUrl('/')
        },1000)
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
