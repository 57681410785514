import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ServerError } from '../enums/ServerStatus';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor{
  
  constructor( private router: Router ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verificamos si la URL pertenece a AWS
    const excludedDomains = ['amazonaws.com'];
    if (excludedDomains.some(domain => req.url.includes(domain))) {
      const modifiedRequest = req.clone({
        headers: req.headers.delete('Authorization')
      });
      return next.handle(modifiedRequest);
    }


    const token = localStorage.getItem('token');

    let request = req;
    
    if (token){      
      request = req.clone({
        setHeaders:{
          //'Content-Type': 'application/json',
          'Authorization': token
        }
      })
    } else {      
      request = req.clone({
        setHeaders:{
          // 'Content-Type': 'application/json'
        }
      })
    }

    // return next.handle(request);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === ServerError.ACCESS_ERROR) {
          this.router.navigateByUrl('/auth');
        }

        return throwError( err );

      })
    )

  }


}
