import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Routing } from '../../classes/Routing';
import { tokenExists } from '../../helpers/tokenReader';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(private router:Router){}

  canActivate(): boolean {   
      if (tokenExists()) {
        this.redirect();
        return false;
      } else {       
        return true;        
      }
  }

  redirect() {    
    Routing.redirect(this.router);    
  }

}
