// El archivo environment.prod.ts es utilizado en Angular para definir variables de entorno específicas 
// para producción. Al establecer la propiedad "production" en "true", se indica que la aplicación está 
// en modo producción y se pueden realizar cambios en el comportamiento de la aplicación en consecuencia, 
// como por ejemplo desactivar mensajes de depuración o enviar datos a un servidor de producción en lugar 
// de a uno de prueba. Este archivo es reemplazado por environment.ts durante el proceso de compilación 
// para generar una versión de producción de la aplicación.

export const environment = {
  production: true
};
