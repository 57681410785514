import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { tokenExists } from '../../helpers/tokenReader';
import { Global } from 'src/app/global/Global';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

constructor(
  private router:Router,
){}

  canActivate():boolean {
    if(tokenExists()){
      return true
    }

    this.redirect();
    return false;
  }
  redirect() {
      this.router.navigate(['/auth']);
  }
  
}
