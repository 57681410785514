import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NoAuthGuard } from './core/guards/noAuth/no-auth.guard';
import { ClienteGuard } from './core/guards/profiles/cliente.guard';
import { DelegadohGuard } from './core/guards/profiles/delegado.guard';
import { EditorhGuard } from './core/guards/profiles/editor.guard';
import { SuperAdminGuard } from './core/guards/profiles/superAdmin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./public/public-app.module').then(m => m.PublicAppModule) // Rutas públicas
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./private/private-app.module').then(m => m.PrivateAppModule) // Rutas privadas
  },
  {
    path: '**',
    redirectTo: 'error'
  },
  {
    path: 'error',
    pathMatch: 'full',
    loadChildren: () => import('./layout/error-screen/error-screen.module').then(m => m.ErrorScreenModule)
  }
];

/*const routes: Routes = [
  {
    path: 'auth',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: 'verificacion-a2f/:token',
    pathMatch:'full',
    canActivate:[NoAuthGuard],
    children:[
      {
        path:'',
        loadChildren: () => import('./layout/verificacion-a2f/verificacion-a2f.module').then(m=> m.VerificacionA2fModule)
      }
    ]
  },
  { path: '',
    pathMatch: 'full',
    redirectTo: 'auth' },
    {
      path: 'verificacion-a2f/:token',
      pathMatch: 'full',
      canActivate:[NoAuthGuard],
      children: [
        {
          path: '',
          loadChildren: () => import('./layout/verificacion-a2f/verificacion-a2f.module').then(m => m.VerificacionA2fModule)
        }
      ]
    },
  {
    path: 'auth/registrar',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/signin/sign-in.module').then(m => m.SignInModule)
      }
    ]
  },
  {
    path: 'auth/registrar/:uuid',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/registro-miembros/registro-miembros.module').then(m => m.RegistroMiembrosModule)
      }
    ]
  },
  {
    path: 'postularse',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/postularse/postularse.module').then(m => m.PostularseModule)
      }
    ]
  },
  {
    path: 'postularse/relevador',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/relevador/registro-relevador/registro-relevador.module').then(m => m.RegistroRelevadorModule)
      }
    ]
  },
  {
    path: 'postularse/editor',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/delegado/registro/registro.module').then(m => m.RegistroEditorModule)
      }
    ]
  },
  {
    path: 'error',
    pathMatch: 'full',
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/error-screen/error-screen.module').then(m => m.ErrorScreenModule)
      }
    ]
  },
  {
    path: 'forgotten-password',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule)
      }
    ]
  },
  {
    path: 'insert-password/:uuid',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/insert-password/insert-password.module').then(m => m.InsertPasswordModule)
      }
    ]
  },
  {
    path: 'home',
    pathMatch: 'full',
    canActivate:[AuthGuard, ClienteGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/cliente/inicio/inicio.module').then(m => m.InicioModule)
      }
    ]
  },
  {
    path: 'verify/:uuid',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/verify/verify.module').then(m => m.VerifyModule)
      }
    ]
  },
  {
    path: 'test',
    pathMatch: 'full',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/verify/verify.module').then(m => m.VerifyModule)
      }
    ]
  },
  {
    path: 'verify/:tipoCuenta/:uuid',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/verify/verify.module').then(m => m.VerifyModule)
      }
    ]
  },
  {
    //antigua ruta: recovery/:uuid
    path: 'recovery/password/:uuid',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/recovery/recovery.module').then(m => m.RecoveryModule)
      }
    ]
  },
  {
    path: 'change-mail/:uuid',
    pathMatch:'full',
    canActivate:[NoAuthGuard],
    children:[
      {
        path:'',
        loadChildren: () => import('./modules/auth/change-mail/change-mail.module').then(m=> m.ChangeMailModule)
      }
    ]
  },
  {
    path: 'modificar-correo',
    pathMatch: 'full',
    canActivate:[NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/modificar-correo/modificar-correo.module').then(m => m.ModificarCorreoModule)
      }
    ]
  },
  {
    path: 'relevador',
    pathMatch: 'full',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/relevador/inicio-relevador/inicio-relevador.module').then(m => m.InicioRelevadorModule)
      }
    ]
  },
  {
    path: 'mi-perfil',
    pathMatch: 'full',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/perfil/perfil.module').then(m => m.PerfilModule)
      }
    ]
  },
  {
  path: 'resolver-validacion',
  pathMatch: 'full',
  canActivate: [NoAuthGuard],
  children:[
    {
      path:'',
      loadChildren: () => import('./layout/resolver-validacion/resolver-validacion.module').then(m => m.ResolverValidacionModule)
    }
  ]
  },
  {
    path: 'delegado',
    pathMatch: 'full',
    canActivate:[AuthGuard, DelegadohGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/delegado/home/home.module').then(m => m.HomeEditorModule)
      }
    ]
  },
  {
    path: 'editor',
    pathMatch: 'full',
    canActivate:[AuthGuard, EditorhGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/editor/home/home.module').then(m => m.HomeEditorModule)
      }
    ]
  },
  {
    path: 'guias-y-tips',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./global/guias-usuario/guias-usuario.module').then(m => m.GuiasUsuarioModule)
      }
    ]
  },
  {
    path: 'guia/:slug',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./global/guias-y-tips/guia/guia.module').then(m => m.GuiaModule)
      }
    ]
  },
  {
    // testing/:idPedido
    path: 'pedido/:idPedido',
    pathMatch: 'full',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./global/detalle-pedido/detalle-pedido.module').then(m => m.DetallePedidoModule)
      }
    ]
  },
  {
    path: 'superadmin',
    pathMatch: 'full',
    canActivate:[AuthGuard, SuperAdminGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/superadmin/routing-sa/routing-sa.module').then(m => m.RoutingSAModule)
      }
    ]
  },
  {
    path: 'terminos/:tipoCuenta',
    pathMatch: 'full',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/terminos-y-condiciones/terminos-y-condiciones.module').then(m => m.TerminosYCondicionesModule)
      }
    ]
  },  
  {
    path: 'dashboard',
    pathMatch: 'full',
    canActivate:[AuthGuard, SuperAdminGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/superadmin/config-sa/financiero/financiero.module').then(m => m.FinancieroModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo:'error'
  }
];*/

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
